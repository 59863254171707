.proposals-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between cards */
  }
  
  .proposal-card {
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 20px;
    width: calc(33.333% - 20px); /* Adjust width for 3 cards in a row, accounting for gap */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .proposal-card h3 {
    margin-top: 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .proposal-card {
      width: calc(50% - 20px); /* Adjust for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .proposal-card {
      width: 100%; /* Full width on very small screens */
    }
  }
  