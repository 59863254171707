/* Hero.css */
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff; 
  color: #000000;
  padding: 0 20px;
  text-align: center;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  max-width: 1200px; 
  margin: 0 auto;
}

.title {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeInTitle 2s ease-in-out forwards;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 30px;
  opacity: 0;
  animation: fadeInSubtitle 2.5s ease-in-out forwards;
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInSubtitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  animation: fadeInImage 2s ease-in-out forwards;
}


@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cta-container {
  margin-top: 40px;
  opacity: 0;
  animation: fadeInCTA 3s ease-in-out forwards;
}

.cta-button {
  background-color: #4CAF50; /* Green button */
  color: #fff;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45a049;
}

@keyframes fadeInCTA {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .hero-content {
    padding: 0 15px;
  }
  .title {
    font-size: 2.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .blockchain-image {
    width: 250px; /* Adjust size for mobile screens */
  }
}
